export default [
    {
        path: '/c/:company_id/salesreport',
        name: 'salesreport',
        component: () => import('@/views/info/report/sales.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/salesreport_by_product',
        name: 'salesreport_by_product',
        component: () => import('@/views/info/report/salesbyproduct.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/salesreport_by_channel',
        name: 'salesreport_by_channel',
        component: () => import('@/views/info/report/salesbychannel.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/salesreport_by_document',
        name: 'salesreport_by_document',
        component: () => import('@/views/info/report/salesbydocument.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/salesreport_by_supplier',
        name: 'salesreport_by_supplier',
        component: () => import('@/views/info/report/salesbysupplier.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/stockvaluereport',
        name: 'stockvaluereport',
        component: () => import('@/views/info/report/stockvalue.vue'),
        meta: {
            layout: 'content',
            resource: 'Report',
            action: 'read'
        },
    },
    {
      path: '/c/:company_id/salesbyproductwithorder',
      name: 'salesbyproductwithorder',
      component: () => import('@/views/info/report/salesbyproductwithorder.vue'),
      meta: {
          layout: 'content',
          resource: 'Report',
          action: 'read'
      },
  }
]
