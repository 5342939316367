<template>
    <div>
        <div style="
        width: 100%;
        justify-content: center;
        page-break-after: always;
        box-sizing: border-box;
        position: relative;
        padding: 20px;
        flex-direction:column;
        "
        class="page"
        v-for="(order, page_index) in printData" :key="'page_' + page_index"
        >
            <div style="display:flex;justify-content:center;font-weight:bold;font-size:26px">
                <span style="display:inline-flex">ใบคำสั่งซื้อ</span><span style="display:inline-flex;color:white;background-color:black;margin-left:5px;padding-left:10px;padding-right:10px">#{{ order.orderNumber }}</span>
            </div>
            <div style="display:flex">
                <div style="display:inline-flex;width:50%;flex-direction:column">
                    <div style="font-size:20px;padding-top:5px">
                        ร้านค้าผู้ให้บริการ
                    </div>
                    <div style="padding-top:5px">
                        {{ company.name }}
                    </div>
                    <div style="padding-top:5px">
                        {{ company.address }} {{ company.city }} {{ company.county }} {{ company.province }} {{ company.postcode }}
                    </div>
                    <div style="padding-top:5px">
                        เลขประจำตัวผู้เสียภาษี {{ company.taxid }}
                    </div>
                    <div style="padding-top:5px">
                        ติดต่อ {{ company.tel }}
                    </div>
                </div>
                <div style="display:inline-flex;width:50%;flex-direction:column">
                    <div style="font-size:20px;padding-top:5px">
                        ลูกค้า
                    </div>
                    <div style="padding-top:5px">
                        {{ order.name }}
                    </div>
                    <div style="padding-top:5px">
                        {{ order.fullAddress }}
                    </div>
                    <div style="padding-top:5px">
                        ติดต่อ {{ order.tel }}
                    </div>
                </div>
            </div>
            <div style="display:flex;">
                <table style="width:100%;border-collapse: collapse;">
                    <thead>
                        <tr>
                            <th width="10%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                ลำดับ
                            </th>
                            <th width="100px" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                รูป
                            </th>
                            <th width="20%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                รายการ
                            </th>
                            <th width="20%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                ราคาต่อหน่วย
                            </th>
                            <th width="20%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                ส่วนลดต่อหน่วย
                            </th>
                            <th width="10%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                จำนวน
                            </th>
                            <th width="20%" style="border-top: 1px solid black;border-bottom: 1px solid black">
                                จำนวนเงิน
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in order.orderItems" :key="'product_' + index">
                            <td style="text-align:center;">
                                {{ index + 1 }}
                            </td>
                            <td style="">
                                <img :src="item.imageURL" alt="" style="max-width:100px;max-height:100px">
                            </td>
                            <td style="">
                                {{ item.name }}
                                <br>
                                {{ item.sku }}
                                <br v-if="item.note">
                                {{ item.note }}
                            </td>
                            <td style="text-align:right">
                                {{ item.price * 1 | formatNumber }}
                            </td>
                            <td style="text-align:right">
                                {{ (item.discount ? item.discount * 1 : 0) | formatNumber }} {{ item.discount_type == 'percent' ? '%' : '' }}
                            </td>
                            <td style="text-align:center">
                                {{ item.quantity }} {{ item.unit }}
                            </td>
                            <td style="text-align:right">
                                {{ item.priceSum | formatNumber }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7">
                                <div style="display:flex;width:100%;page-break-inside:avoid;flex-direction:column;margin-top:auto">
                                    <div style="display:flex;width:100%;border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px"></div>
                                    <div style="display:flex;width:100%">
                                        <div style="display:inline-flex;width:50%;flex-direction:column">
                                        </div>
                                        <div style="display:inline-flex;width:25%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                รวมเป็นเงิน
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                ส่วนลดทั้งบิล
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                จำนวนเงินหลักหักส่วนลด
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black">
                                                ค่าจัดส่ง
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                (รวมภาษีมูลค่าเพิ่ม) รวม
                                            </div>
                                            <div style="display:flex;border-bottom:1px solid black"></div>
                                            <div style="display:flex;border-bottom:2px solid black;padding-top:2px;"></div>
                                        </div>
                                        <div style="display:inline-flex;width:25%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ order.priceBeforeDiscount | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ (order.discount ? order.discount * 1 : 0) | formatNumber }} {{ (order.discount_type == 'percent' ? '%' : '') }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ order.priceAfterDiscount | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black">
                                                {{ order.delivery_cost * 1 | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{order.totalPrice | formatNumber}}
                                            </div>
                                            <div style="display:flex;border-bottom:1px solid black"></div>
                                            <div style="display:flex;border-bottom:2px solid black;padding-top:2px;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display:flex;width:100%;padding-top:10px">
                                    <div style="display:inline-flex;width:50%">
                                        หมายเหตุ
                                    </div>
                                    <div style="display:inline-flex;width:50%">
                                        หมายเหตุลูกค้า
                                    </div>
                                </div>
                                <div style="display:flex;width:100%">
                                    <div style="display:inline-flex;width:50%">
                                        {{ order.note }}
                                    </div>
                                    <div style="display:inline-flex;width:50%">
                                        {{ order.customer_note }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
}
</script>
