<template>
    <div>
        <div v-for="(order, page_index) in printData" :key="'page_' + page_index">
            <div style="
                width:100%;
                justify-content: center;
                page-break-after: always;
                box-sizing:border-box;
                display:flex;
                min-height:220mm;position:relative;
                padding: 20px;
                "
                class="page"
            >
                <table style="width:100%;border-collapse: collapse">
                    <thead>
                        <tr style="padding-bottom:10px">
                            <th colspan="8">
                                <div style="display:flex;width:100%">
                                    <div style="display:inline-flex;flex-basis:50%;flex-direction:column">
                                        <div style="display:flex;">
                                            <div style="display:inline-flex;">
                                                <img :src="company.profile" alt="" style="max-height:100px;max-width:100px">
                                            </div>
                                            <div style="display:inline-flex;flex-direction:column;margin-left:10px">
                                                <div style="display:flex;font-weight:900;font-size:20px;text-align:left">{{ company.name }}</div>
                                                <div style="display:flex;font-size:16px;font-weight:400">{{ order.branch }}</div>
                                                <template v-if="(order instanceof Order)">
                                                    <div style="display:flex;font-size:16px;text-align:left;font-weight:400">{{ company.address }} {{ company.city }} {{ company.county }} {{ company.province }} {{ company.postcode }}</div>
                                                    <div style="display:flex;font-size:16px;text-align:left;font-weight:400">{{ company.tel }}</div>
                                                </template>
                                                <template v-else>
                                                    <div style="display:flex;font-size:16px;text-align:left;font-weight:400">{{ order.headerAddress }}</div>
                                                    <div style="display:flex;font-size:16px;text-align:left;font-weight:400">{{ order.tel }}</div>
                                                </template>
                                                <div style="display:flex;font-size:16px;text-align:left;font-weight:400" v-if="order.VAT">เลขประจำตัวผู้เสียภาษี {{ company.taxid }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex;flex-basis:50%;flex-direction:column">
                                        <div style="display:flex;width:100%;border-bottom:1px solid black;width:100%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;font-size:24px;font-weight:bold">{{ order.VAT ? 'ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน/ใบส่งของ' : 'ใบเสร็จรับเงิน/ใบส่งของ'}}</div>
                                        </div>
                                        <div style="display:flex;flex-direction:column;width:100%;margin-top:10px;font-weight:400">
                                            <div style="display:flex;align-items:baseline;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">เลขที่</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.abbNumber }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;align-items:baseline;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">วันที่</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.paidAt }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;align-items:baseline;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">พนักงานขาย</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.seller }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;align-items:baseline;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">อ้างอิง</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.orderNumber }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr style="height:30px">
                        </tr>
                        <tr style="">
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:5%">#</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:30%">ชื่อสินค้า</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:15%">
                                <div>ราคา/หน่วย</div>
                                <div style="font-size:12px" v-if="order.VAT">รวมภาษีมูลค่าเพิ่ม</div>
                            </th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:10%">ส่วนลด</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:10%">จำนวน</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:15%">
                                <div>ราคารวม</div>
                                <div style="font-size:12px">หลังหักส่วนลด</div>
                            </th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:15%" v-if="order.VAT">
                                <div>VAT</div>
                                <div style="font-size:12px">ภาษีมูลค่าเพิ่ม</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product,index) in order.orderItems" :key="'product_' + index">
                            <td style="text-align:center">{{ index + 1 }}</td>
                            <td>
                                {{ product.name }}
                                <div style="opacity:0.8;font-size:14px">{{ product.sku }}</div>
                                <div style="font-size:14px" v-if="product.note">
                                    หมายเหตุ : {{ product.note }}
                                </div>
                            </td>
                            <td style="text-align:right">{{ product.price }}</td>
                            <td style="text-align:right">{{ (product.discount ? product.discount * 1 : 0) | formatNumber }} {{ product.discount_type == 'percent' ? '%' : '' }}</td>
                            <td style="text-align:right">{{ product.quantity }} {{ product.unit }}</td>
                            <td style="text-align:right">{{ product.priceSum | formatNumber }}</td>
                            <td style="text-align:right" v-if="order.VAT">
                                {{ (order.VAT && product.is_vat ? product.VATInValue : 0) | formatNumber }}
                            </td>
                        </tr>
                        <template v-if="5 - order.orderItems.length > 0">
                            <tr v-for="pd in 5 - order.orderItems.length" :key="'temp_' + pd">
                                <td colspan="7" style="padding-top:50px;padding-bottom:50px"></td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="7">
                                <div style="display:flex;width:100%;page-break-inside:avoid;flex-direction:column;margin-top:auto">
                                    <div style="display:flex;width:100%;border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px"></div>
                                    <div style="display:flex;width:100%">
                                        <div style="display:inline-flex;width:50%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center"></div>
                                            <div style="display:flex;height:30px;align-items:center">
                                                ({{ order.totalPrice | number2Text }})
                                            </div>
                                        </div>
                                        <div style="display:inline-flex;width:25%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                รวมเป็นเงิน
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                ส่วนลดทั้งบิล
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                จำนวนเงินหลักหักส่วนลด
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black">
                                                ค่าจัดส่ง
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center" v-if="order.VAT">
                                                ภาษีมูลค่าเพิ่ม {{ order.VAT }} %
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black" v-if="order.VAT">
                                                ราคาก่อนภาษีมูลค่าเพิ่ม
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ order.VAT ? '(รวมภาษีมูลค่าเพิ่ม)' : '' }} รวม
                                            </div>
                                            <div style="display:flex;border-bottom:1px solid black"></div>
                                            <div style="display:flex;border-bottom:2px solid black;padding-top:2px;"></div>
                                        </div>
                                        <div style="display:inline-flex;width:25%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ order.priceBeforeDiscount | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ (order.discount ? order.discount * 1 : 0) | formatNumber }} {{ (order.discount_type == 'percent' ? '%' : '') }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{ order.priceAfterDiscount | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black">
                                                {{ order.delivery_cost ? order.delivery_cost * 1 : 0 | formatNumber }}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center" v-if="order.VAT">
                                                {{order.VATSum | formatNumber}}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center;border-bottom:1px solid black" v-if="order.VAT">
                                                {{order.priceBeforeVAT | formatNumber}}
                                            </div>
                                            <div style="display:flex;justify-content: flex-end;height:30px;align-items:center">
                                                {{order.totalPrice | formatNumber}}
                                            </div>
                                            <div style="display:flex;border-bottom:1px solid black"></div>
                                            <div style="display:flex;border-bottom:2px solid black;padding-top:2px;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display:flex;width:100%;padding-top:10px">
                                    <div style="display:inline-flex;width:50%">
                                        หมายเหตุ
                                    </div>
                                    <div style="display:inline-flex;width:50%">
                                        ชำระโดย
                                    </div>
                                </div>
                                <div style="display:flex;width:100%">
                                    <div style="display:inline-flex;width:50%">
                                        {{ order.customer_note }}
                                    </div>
                                    <div style="display:inline-flex;width:50%">
                                        {{ order.payment }}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="!!order.trd || order.status == 'cancelled'" style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%) rotate(45deg);border-style: solid;border-color:red;padding:10px;color:red">
                    <div style="font-size:2.5em;text-align:center">ยกเลิก</div>
                    <div v-if="!!order.trd" style="font-size:1.25em;text-align:center">ออกใบกำกับแบบเต็มแล้ว</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Order } from '@/classes/order/Order'

export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
  data() {
    return {
      Order: Order,
    }
  },
}
</script>
