<template>
    <div>
        <div style="
        width: 100%;
        justify-content: center;
        page-break-after: always;
        position: relative;
        padding: 20px;
        "
        class="page"
        v-if="printData && printData.length > 1"
        >
            <div style="display:flex;justify-content:center;font-weight:bold;font-size:26px">
                สรุปใบจัดของ (Pick List Summary)
            </div>
            <div style="display:flex;margin-top:10px">
                <div style="display:inline-flex;width:50%;font-weight:bold">จำนวนบิล : {{ printData ? printData.length : 0 }}</div>
                <div style="display:inline-flex;width:50%;font-weight:bold;justify-content:flex-end">พิมพ์เมื่อ : {{ printDate }}</div>
            </div>
            <table style="width:100%;border: 1px solid black;border-collapse: collapse;">
              <thead>
                  <tr>
                      <th width="10%" style="border: 1px solid black">
                          ลำดับ
                      </th>
                      <th width="100px" style="border: 1px solid black">
                          รูป
                      </th>
                      <th width="30%" style="border: 1px solid black">
                          รายการ
                      </th>
                      <th width="40%" style="border: 1px solid black">
                          บาร์โคด
                      </th>
                      <th width="20%" style="border: 1px solid black">
                          จำนวน
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, index) in summary" :key="index">
                      <td style="text-align:center;border: 1px solid black">
                          {{ index + 1 }}
                      </td>
                      <td style="border: 1px solid black;">
                          <img :src="item.imageURL" alt="" style="max-width:100px;max-height:100px;">
                      </td>
                      <td style="border: 1px solid black">
                          {{ item.name }}
                          <br>
                          {{ item.sku }}
                      </td>
                      <td style="border: 1px solid black;text-align: center;" class="barcode">
                          {{ !!item.barCode ? '*' + item.barCode + '*' : '' }}
                      </td>
                      <td style="text-align:center;border: 1px solid black">
                          {{ item.quantity }} {{ item.unit }}
                      </td>
                  </tr>
                  <tr>
                      <td colspan="4" style="text-align:center;border: 1px solid black">
                          รวม
                      </td>
                      <td style="text-align:center;border: 1px solid black">
                          {{ printData.flatMap(item => item.orderItems).map(item => item.quantity).reduce((prev, current, index) => prev + current, 0) }} หน่วย
                      </td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div style="
        width: 100%;
        page-break-after: always;
        position: relative;
        padding: 20px;
        "
        class="page"
        v-for="(order, index) in printData" :key="'order_' + index"
        >
            <div style="display:flex;justify-content:center;font-weight:bold;font-size:26px;">
                ใบจัดของ (Pick List)
            </div>
            <div style="display:flex;margin-top:10px">
                <div style="display:inline-flex;width:50%;font-weight:bold">เลขที่บิล : {{ order.orderNumber }}</div>
                <div style="display:inline-flex;width:50%;font-weight:bold;justify-content:flex-end">พิมพ์เมื่อ : {{ printDate }}</div>
            </div>
            <table style="width:100%;border: 1px solid black;border-collapse: collapse;">
                <thead>
                    <tr>
                        <th width="10%" style="border: 1px solid black">
                            ลำดับ
                        </th>
                        <th width="150px" style="border: 1px solid black">
                            รูป
                        </th>
                        <th width="30%" style="border: 1px solid black">
                            รายการ
                        </th>
                        <th width="40%" style="border: 1px solid black">
                            บาร์โคด
                        </th>
                        <th width="20%" style="border: 1px solid black">
                            จำนวน
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in order.orderItems" :key="'product_' + index">
                        <td style="text-align:center;border: 1px solid black">
                            {{ index + 1 }}
                        </td>
                        <td style="border: 1px solid black;">
                            <img :src="item.imageURL" alt="" style="max-width:100px;max-height:100px">
                        </td>
                        <td style="border: 1px solid black">
                            {{ item.name }}
                            <br>
                            {{ item.sku }}
                        </td>
                        <td style="border: 1px solid black;text-align: center;" class="barcode">
                            {{ !!item.barCode ? '*' + item.barCode + '*' : '' }}
                        </td>
                        <td style="text-align:center;border: 1px solid black">
                            {{ item.quantity }} {{ item.unit }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align:center;border: 1px solid black">
                            รวม
                        </td>
                        <td style="text-align:center;border: 1px solid black">
                            {{ productCount(order.orderItems) }} หน่วย
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <div style="display:flex;width:100%;padding-top:10px">
                                <div style="display:inline-flex;width:50%;">
                                    หมายเหตุ :
                                </div>
                                <div style="display:inline-flex;width:50%;">
                                    หมายเหตุลูกค้า :
                                </div>
                            </div>
                            <div style="display:flex;width:100%;padding-top:10px">
                                <div style="display:inline-flex;width:50%">
                                    {{ order.note }}
                                </div>
                                <div style="display:inline-flex;width:50%">
                                    {{ order.customer_note }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { convertDateTomeToAooDefault } from '@/helpers/converter'
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
  watch: {
    printData() {
      this.summary = []

      const flatted = this.printData
        .flatMap(item => item.orderItems)
        .map(item => {
          return {
            imageURL: item.imageURL,
            name: item.name,
            sku: item.sku,
            barCode: item.barCode,
            quantity: item.quantity,
            unit: item.unit,
            id: item.id,
          }
        })
        .forEach(item => {
          const found = this.summary.find(find => find.id == item.id)

          if (!found) return this.summary.push(item)

          found.quantity = found.quantity + item.quantity
        })
    },
  },
  data() {
    return {
      printDate: convertDateTomeToAooDefault(new Date()),
      summary: [],
      /*
      summary: this.printData
        .map(order => order.orderItems)
        .reduce((accumulator, currentValue) => {
          const currentAccumulator = [...accumulator]

          currentValue.forEach(currentProductItem => {
            const found = currentAccumulator.find(find => find.id == currentProductItem.id)

            if (!found) return currentAccumulator.push(currentProductItem)

            found.quantity += currentProductItem.quantity
          })

          return currentAccumulator
        }, []),
        */
    }
  },
  created() {
    //console.log(this.printData.flatMap(item => item.orderItems))
    //printData.flatMap(item => item.orderItems.quantity).reduce((prev, current, index) => prev + current, 0)

    const flatted = this.printData
      .flatMap(item => item.orderItems)
      .map(item => {
        return {
          imageURL: item.imageURL,
          name: item.name,
          sku: item.sku,
          barCode: item.barCode,
          quantity: item.quantity,
          unit: item.unit,
          id: item.id,
        }
      })
      .forEach(item => {
        const found = this.summary.find(find => find.id == item.id)

        if (!found) return this.summary.push(item)

        found.quantity = found.quantity + item.quantity
      })
    /*
    console.log(
      this.printData
        .flatMap(item => item.orderItems)
        .map(item => item.quantity)
        .reduce((prev, current, index) => prev + current, 0),
    )
    */
    // const test = this.printData.flatMap(item => item.orderItems.quantity)
  },
  methods: {
    productCount(orderItems) {
      /*
      let sum = 0
      orderItems.forEach(e => {
        sum += e.quantity
      })

      return sum
      */
      return orderItems.map(item => item.quantity).reduce((prev, cur, index) => prev + cur, 0)
    },
  },
}
</script>
</script>
