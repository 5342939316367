<template>
    <div style="
    display: flex;
    flex-wrap: wrap;
    "
    class="page"
    >
        <template v-for="(order, page_index) in printData">
            <div style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px solid black;
            font-family: kanit;
            padding-left: 5px;
            padding-right: 5px;
            align-items: flex-start;
            flex-grow: 1;
            "
            :key="'page_' + page_index"
            v-if="!order.shippopLabel"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            >
                <div style="display:inline-flex;border-bottom: 1px solid black ;width:100%;padding: 5px 10px 5px 10px;font-size:17px">
                    เลขที่บิล #{{ order.orderNumber }}<!--<span style="margin-left:auto">{{ order.delivery_method.name }}</span>-->
                </div>
                <div style="display:inline-flex;border-bottom: 1px solid black ;width:100%;flex-direction:column;padding: 10px;">
                    <div style="display:flex;width:100%;font-weight:500;font-size:21px">
                        ผู้รับ (Receiver)<span style="margin-left:auto">Tel : {{ order.tel }}</span>
                    </div>
                    <div style="display:flex;width:100%;margin-top:5px;font-size:14px">Name: {{ order.name }}</div>
                    <div style="display:flex;width:100%;margin-top:5px;font-size:14px">Address: {{ order.fullAddress }}</div>
                    <template v-if="order.customer_note">
                        <div style="display:flex;width:100%;margin-top:5px;font-size:21px;font-weight:500">หมายเหตุ :</div>
                        <div style="display:flex;width:100%;margin-top:5px;font-size:14px">{{order.customer_note}}</div>
                    </template>
                </div>
                <div style="display:inline-flex;width:100%;flex-direction:column;padding: 10px;">
                    <div style="display:flex;width:100%;font-size:14px">
                        ผู้ส่ง (Sender)<span style="margin-left:auto">Tel : {{ company.tel }}</span>
                    </div>
                    <div style="display:flex;width:100%;margin-top:5px;font-size:12px">{{ company.name }}</div>
                    <div style="display:flex;width:100%;margin-top:5px;font-size:12px">{{ company.send_address }} {{ company.send_city }} {{ company.send_county }} {{ company.send_province }} {{ company.send_postcode }}</div>
                </div>
                <table
                    v-if="$store.getters['app/GET_COVER_PRINT_PRODUCT']"
                    style="width:100%;margin: 0 0 5px 0; padding: 0;border-spacing: 0"
                >
                    <thead>
                        <tr>
                            <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px 10px 5px 10px;font-size: 12px;font-weight: 400;width:30%">รหัสสินค้า</th>
                            <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px;font-size: 12px;font-weight: 400">รายการสินค้า</th>
                            <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px 10px 5px 10px;font-size: 12px;width:10%;font-weight: 400">จำนวน</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(product, index) in order.orderItems" :key="index"
                            style="font-size: 12px"
                        >
                            <td style="padding: 5px 10px 5px 10px">{{ product.sku }}</td>
                            <td style="padding: 5px">{{ product.name }}</td>
                            <td style="padding: 5px 10px 5px 10px;text-align:right">{{ product.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
                <div
                    style="display:flex;border-top: 1px solid black ;width:100%;flex-direction:row;padding:10px;font-size:18px;font-weight:500"
                    v-if="order.isCOD"
                >
                    ยอดเก็บเงินปลายทาง<span style="text-align:right; margin-left:auto">{{order.totalPrice | formatNumber}} บาท</span>
                </div>
                <!--
                <div style="
                    display:inline-flex;
                    flex-direction:column;
                    padding-left: 5px; padding-right: 5px
                    "
                >
                    <div style="display:inline-flex;border-bottom: 1px solid black ;width:100%;padding: 5px 10px 5px 10px;font-size:17px">
                        เลขที่บิล #{{ order.orderNumber }}<span style="margin-left:auto">{{ order.delivery_method.name }}</span>
                    </div>
                    <div style="display:inline-flex;border-bottom: 1px solid black ;width:100%;flex-direction:column;padding: 10px;">
                        <div style="display:flex;width:100%;font-weight:500;font-size:21px">
                            ผู้รับ (Receiver)<span style="margin-left:auto">Tel : {{ order.tel }}</span>
                        </div>
                        <div style="display:flex;width:100%;margin-top:5px;font-size:14px">Name: {{ order.name }}</div>
                        <div style="display:flex;width:100%;margin-top:5px;font-size:14px">Address: {{ order.fullAddress }}</div>
                        <template v-if="order.customer_note">
                            <div style="display:flex;width:100%;margin-top:5px;font-size:21px;font-weight:500">หมายเหตุ :</div>
                            <div style="display:flex;width:100%;margin-top:5px;font-size:14px">{{order.customer_note}}</div>
                        </template>
                    </div>
                    <div style="display:inline-flex;width:100%;flex-direction:column;padding: 10px;">
                        <div style="display:flex;width:100%;font-size:14px">
                            ผู้ส่ง (Sender)<span style="margin-left:auto">Tel : {{ company.tel }}</span>
                        </div>
                        <div style="display:flex;width:100%;margin-top:5px;font-size:12px">{{ company.name }}</div>
                        <div style="display:flex;width:100%;margin-top:5px;font-size:12px">{{ company.send_address }} {{ company.send_city }} {{ company.send_county }} {{ company.send_province }} {{ company.send_postcode }}</div>
                    </div>
                    <table
                        v-if="$store.getters['app/GET_COVER_PRINT_PRODUCT']"
                        style="width:100%;margin: 0 0 5px 0; padding: 0;border-spacing: 0"
                    >
                        <thead>
                            <tr>
                                <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px 10px 5px 10px;font-size: 12px;font-weight: 400;width:30%">รหัสสินค้า</th>
                                <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px;font-size: 12px;font-weight: 400">รายการสินค้า</th>
                                <th style="text-align:left;border-bottom: 1px solid black ;border-top: 1px solid black ;padding: 5px 10px 5px 10px;font-size: 12px;width:10%;font-weight: 400">จำนวน</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(product, index) in order.orderItems" :key="index"
                                style="font-size: 12px"
                            >
                                <td style="padding: 5px 10px 5px 10px">{{ product.sku }}</td>
                                <td style="padding: 5px">{{ product.name }}</td>
                                <td style="padding: 5px 10px 5px 10px;text-align:right">{{ product.quantity }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        style="display:flex;border-top: 1px solid black ;width:100%;flex-direction:row;padding:10px;font-size:18px;font-weight:500"
                        v-if="order.isCOD"
                    >
                        ยอดเก็บเงินปลายทาง<span style="text-align:right; margin-left:auto">{{order.totalPrice | formatNumber}} บาท</span>
                    </div>
                </div>
                -->
            </div>
            <flash
            v-else-if="order.shippopLabel.courierCode == 'FLE'"
            style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px dotted black;
            "
            :key="'page_' + page_index"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            :value="order.shippopLabel"
            ></flash>
            <dhl
            v-else-if="order.shippopLabel.courierCode == 'DHL'"
            style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px dotted black;
            "
            :key="'page_' + page_index"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            :value="order.shippopLabel"
            ></dhl>
            <ems
            v-else-if="order.shippopLabel.courierCode == 'EMST'"
            style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px dotted black;
            font-family: kanit;
            "
            :key="'page_' + page_index"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            :value="order.shippopLabel"
            ></ems>
            <kerry
            v-else-if="order.shippopLabel.courierCode == 'KRYX'"
            style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px dotted black;
            font-family: kanit;
            "
            :key="'page_' + page_index"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            :value="order.shippopLabel"
            ></kerry>
            <div style="
            justify-content: center;
            page-break-inside: avoid;
            position: relative;
            display:inline-flex;
            flex-direction:column;
            border: 1px dotted black
            "
            :key="'page_' + page_index"
            :class="[{'a4': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A4', 'a5': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A5', 'a6': $store.getters['app/GET_COVER_PRINT_SIZE'] == 'A6'}]"
            v-else
            >
                <div style="display:flex;width:100%;border-bottom: 1px solid black;padding: 10px;align-items:center">
                    <div style="display: inline-flex;width:50%;flex-direction:column;justify-content: center;align-items:center">
                        <img :src="order.shippopLabel.courierImage" style="max-width:60%" alt="">
                    </div>
                    <div style="display: inline-flex;width:50%;flex-direction:column;justify-content: center;align-items:center">
                        <label for="" style="font-size:2.5rem;font-weight:800;height:100%">{{ order.shippopLabel.courierImage.isCod ? 'COD' : 'Non-COD' }}</label>
                        <label for="" style="font-weight:800;height:100%">#{{ order.orderNumber }}</label>
                    </div>
                </div>
                <div style="display:flex;width:100%;border-bottom: 1px solid black;padding: 10px;justify-content: center;align-items:center">
                    <span>{{ order.shippopLabel.sortingCode.sortCode }}</span>
                    <span>{{ order.shippopLabel.sortingCode.dstCode }}</span>
                    <!--
                    <span for="" style="display:inline-flex;font-size: 4rem;font-weight:800;justify-content: flex-end;align-items:center">{{ getFirstSortCode(order.shippopLabel.sortingCode.sortCode) }}</span><span for="" style="display:inline-flex;justify-content: flex-start;align-items:center;font-size: 2rem;font-weight:800;">{{ getLaterSortCode(order.shippopLabel.sortingCode.sortCode) }}</span>
                    -->
                </div>
                <div style="display:flex;flex-direction:column;width:100%;border-bottom: 1px solid black;padding: 10px;">
                    <div>
                        <span style="font-weight:800;font-size:1.25rem">ผู้ส่ง : </span><span style="font-size:1.25rem">{{ order.shippopLabel.origin.name }} - {{  order.shippopLabel.origin.phone }}</span>
                    </div>
                    <div>
                        {{ order.shippopLabel.origin.address }} {{ order.shippopLabel.origin.subdistrict }} {{ order.shippopLabel.origin.district }} {{ order.shippopLabel.origin.province }} {{ order.shippopLabel.origin.postcode }}
                    </div>
                </div>
                <div style="display:flex;flex-direction:column;width:100%;border-bottom: 1px solid black;padding: 10px;">
                    <div>
                        <span style="font-weight:800;font-size:1.25rem">ผู้รับ : </span><span style="font-size:1.25rem">{{ order.shippopLabel.destination.name }} - {{  order.shippopLabel.destination.phone }}</span>
                    </div>
                    <div>
                        {{ order.shippopLabel.destination.address }} {{ order.shippopLabel.destination.subdistrict }} {{ order.shippopLabel.destination.district }} {{ order.shippopLabel.destination.province }} {{ order.shippopLabel.destination.postcode }}
                    </div>
                </div>
                <div style="display:flex;flex-direction:column;width:100%;padding: 10px;align-items:center">
                    <div class="barcode128">
                        {{ order.shippopLabel.courierTrackingCode }}
                    </div>
                    <div class="barcode-text">
                        {{ order.shippopLabel.courierTrackingCode }}
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>
<script>
import dhl from './labels/dhl.vue'
import ems from './labels/ems.vue'
import flash from './labels/flash.vue'
import kerry from './labels/kerry.vue'
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
  created() {
    console.log(this.$store.getters['app/GET_COVER_PRINT_SIZE'])
  },
  methods: {
    getFirstSortCode(sortCode) {
      return sortCode.split('-')[0]
    },
    getLaterSortCode(sortCode) {
      let splited = sortCode.split('-')
      let ret = ''
      for (let i = 1; i < splited.length; i++) {
        ret += '-' + splited[i]
      }

      return ret
    },
  },
  components: {
    flash,
    dhl,
    ems,
    kerry,
  },
}
</script>
