import { mdiAccountMultiple, mdiAccountOutline, mdiArchiveOutline, mdiCogOutline, mdiCommentProcessingOutline, mdiFileDocument, mdiHomeOutline, mdiPoll, mdiShoppingOutline, mdiWarehouse } from '@mdi/js'

export default {
    namespaced: true,
    getters: {
        GET_SELLING(state, getters, rootState, rootGetters) {
            return [
                {
                    subheader: 'ซื้อ-ขาย-คลัง'
                },
                {
                    title: 'แชต',
                    icon: mdiCommentProcessingOutline,
                    to: { name: 'chat' },
                    resource: 'OnlineOrder',
                    action: 'read',
                    badge: rootGetters['socket/GET_CHAT_UNREAD_COUNT'],
                    badgeColor: 'primary',
                },
                {
                    title: 'รายการขาย',
                    icon: mdiShoppingOutline,
                    children: [
                        {
                        title: 'รายการขาย',
                        to: { name: 'saleorder' },
                        resource: 'OnlineOrder',
                        action: 'read'
                        },
                        {
                            title: 'รายการรอส่ง',
                            to: { name: 'pendingdelivery' },
                            resource: 'OnlineOrder',
                            action: 'read'
                            },
                        {
                        title: 'รายการขาย POS',
                        to: { name: 'pos' },
                        resource: 'PosOrder',
                        action: 'read'
                        },
                    ],
                },
                {
                    title: 'จัดซื้อ',
                    icon: mdiShoppingOutline,
                    children: [
                        {
                        title: 'คำสั่งซื้อ (PO)',
                        to: { name: 'po' },
                        resource: 'BuyOrder',
                        action: 'read',
                        },
                        {
                        title: 'รับสินค้า/เอาของเข้า',
                        to: { name: 'rog' },
                        resource: 'BuyOrder',
                        action: 'read',
                        },
                    ],
                },
                {
                    title: 'สต็อก/คลัง',
                    icon: mdiWarehouse,
                    children: [
                        {
                            title: 'สต็อก',
                            to: { name: 'stock' },
                            resource: 'Inventory',
                            action: 'read',
                        },
                        {
                            title: 'โอนย้ายสินค้า',
                            to: { name: 'ivm' },
                            resource: 'Inventory',
                            action: 'read',
                        },
                        {
                            title: 'คืน/ตัด สินค้า',
                            to: { name: 'retog' },
                            resource: 'Inventory',
                            action: 'read',
                        },
                        {
                            title: 'จัดการคลัง/สาขา',
                            to: { name: 'warehouse' },
                            resource: 'Inventory',
                            action: 'read',
                        },
                        {
                            title: 'แชร์สต็อก',
                            to: { name: 'shareablelink' },
                            resource: 'Inventory',
                            action: 'read',
                        },
                    ],
                },
            ]
        },
        GET_INFO() {
            return [
                {
                    subheader: 'ข้อมูล'
                },
                {
                    title: 'สินค้า',
                    icon: mdiArchiveOutline,
                    children: [
                      {
                        title: 'สินค้าทั้งหมด',
                        to: { name: 'product' },
                        resource: 'Product',
                        action: 'read',
                      },
                      {
                        title: 'หมวดหมู่',
                        to: { name: 'category' },
                        resource: 'Product',
                        action: 'read',
                      },
                      {
                        title: 'แท็กสินค้า',
                        to: { name: 'tag' },
                        resource: 'Product',
                        action: 'read',
                      },
                      {
                        title: 'แบรนด์',
                        to: { name: 'brand' },
                        resource: 'Product',
                        action: 'read',
                      },
                      {
                        title: 'ซัพพลายเออร์',
                        to: { name: 'supplier' },
                        resource: 'Product',
                        action: 'read',
                      },
                    ],
                },
                {
                    title: 'ลูกค้า',
                    icon: mdiAccountMultiple,
                    action: 'read',
                    children: [
                        {
                            title: 'รายชื่อลูกค้า',
                            to: { name: 'customer' },
                            resource: 'Customer',
                            action: 'read',
                        },
                        {
                            title: 'แท็กลูกค้า',
                            to: { name: 'customertag' },
                            resource: 'Customer',
                            action: 'read',
                        },
                    ],
                },
                {
                    title: 'รายงาน',
                    icon: mdiPoll,
                    children: [
                      {
                        title: 'ยอดขาย',
                        to: { name: 'salesreport' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'ยอดขายตามสินค้า',
                        to: { name: 'salesreport_by_product' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'ยอดขายตามช่องทาง',
                        to: { name: 'salesreport_by_channel' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'Express ตามสินค้า',
                        to: { name: 'salesreport_by_document' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'รายงานตามซัพพลายเออร์',
                        to: { name: 'salesreport_by_supplier' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'มูลค่าของคงคลัง',
                        to: { name: 'stockvaluereport' },
                        resource: 'Report',
                        action: 'read',
                      },
                      {
                        title: 'สินค้าตามบิล',
                        to: { name: 'salesbyproductwithorder' },
                        resource: 'Report',
                        action: 'read',
                      },
                    ],
                },
                {
                    title: 'เอกสาร',
                    icon: mdiFileDocument,
                    children: [
                      {
                        title: 'ใบกำกับภาษีแบบเต็ม',
                        to: { name: 'trd' },
                        resource: 'Document',
                        action: 'read',
                      },
                    ],
                },
            ]
        },
        GET_MISC() {
            return [
                {
                    subheader: 'อื่นๆ'
                },
                {
                    title: 'พนักงาน',
                    icon: mdiAccountOutline,
                    children: [
                        /*
                        {
                            title: 'ลิ้งค์คำเชิญ',
                            to: { name: 'invitation' },
                            resource: 'Employee',
                            action: 'read'
                        },
                        */
                        {
                            title: 'รายชื่อพนักงาน',
                            to: { name: 'member' },
                            resource: 'Employee',
                            action: 'read'
                        },
                        {
                          title: 'กลุ่มสิทธิ์',
                          to: { name: 'role' },
                          resource: 'Employee',
                          action: 'read'
                        },
                      ],
                },
                {
                    title: 'ตั้งค่า',
                    icon: mdiCogOutline,
                    children: [
                        {
                            title: 'ข้อมูลร้านค้า',
                            to: { name: 'company_create' },
                            resource: 'Setting',
                            action: 'read'
                        },
                        {
                          title: 'ช่องทางการขาย',
                          to: { name: 'salechannel' },
                          resource: 'Setting',
                          action: 'read'
                        },
                        {
                            title: 'ช่องทางการชำระ',
                            to: { name: 'payment' },
                            resource: 'Setting',
                            action: 'read'
                        },
                        {
                            title: 'วิธีการจัดส่ง',
                            to: { name: 'delivery' },
                            resource: 'Setting',
                            action: 'read'
                        },
                        {
                            title: 'การพิมพ์',
                            to: { name: 'print_create' },
                            resource: 'Setting',
                            action: 'read'
                        },
                        {
                            title: 'การตอบสนอง',
                            to: { name: 'responserule' },
                            resource: 'Setting',
                            action: 'read'
                        },
                      ],
                },
                {
                    title: 'ตั้งค่า POS',
                    icon: mdiCogOutline,
                    children: [
                        {
                            title: 'ช่องทางการชำระ',
                            to: { name: 'pospayment' },
                            resource: 'POSSetting',
                            action: 'read'
                        },
                        {
                            title: 'เครื่อง POS',
                            to: { name: 'device' },
                            resource: 'POSSetting',
                            action: 'read'
                        },
                      ],
                },
            ]
        },
        GET_MENU_ITEMS(state, getters) {
            return [
                {
                    title: 'Home',
                    icon: mdiHomeOutline,
                    to: { name: 'home' },
                    resource: 'Auth',
                    action: 'read'
                },
                ...getters.GET_SELLING,
                ...getters.GET_INFO,
                ...getters.GET_MISC
            ]
        }
    },
}
