<template>
    <div>
        <div v-for="(order, page_index) in printData" :key="'page_' + page_index">
            <div style="
                width:100%;
                justify-content: center;
                page-break-after: always;
                box-sizing:border-box;
                display:flex;
                min-height:220mm;position:relative;
                padding: 20px;
                "
                class="page"
            >
                <table style="width:100%;border-collapse: collapse">
                    <thead>
                        <tr style="padding-bottom:10px">
                            <th colspan="5">
                                <div style="display:flex;width:100%">
                                    <div style="display:inline-flex;flex-basis:50%;flex-direction:column">
                                        <div style="display:flex;">
                                            <div style="display:inline-flex;">
                                                <img :src="company.profile" alt="" style="max-height:100px;max-width:100px">
                                            </div>
                                            <div style="display:inline-flex;flex-direction:column;margin-left:10px">
                                                <div style="display:flex;font-weight:900;font-size:18px;text-align:left">{{ company.name }}</div>
                                                <div style="display:flex;font-size:14px">สำนักงานใหญ่</div>
                                                <div style="display:flex;font-size:14px;text-align:left">{{ company.address }} {{ company.city }} {{ company.county }} {{ company.province }} {{ company.postcode }}</div>
                                                <div style="display:flex;font-size:14px;text-align:left">{{ company.tel }}</div>
                                                <div style="display:flex;font-size:14px;text-align:left">เลขประจำตัวผู้เสียภาษี {{ company.taxid }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display:inline-flex;flex-basis:50%;flex-direction:column">
                                        <div style="display:flex;width:100%;border-bottom:1px solid black;width:100%;flex-direction:column">
                                            <div style="display:flex;justify-content: flex-end;font-size:24px;font-weight:bold">ใบรับของ</div>
                                            <div style="display:flex;justify-content: flex-end;font-size:20px;padding-top:10px;padding-bottom:10px">ต้นฉบับ (เอกสารออกเป็นชุด)</div>
                                        </div>
                                        <div style="display:flex;flex-direction:column;width:100%;margin-top:10px">
                                            <div style="display:flex">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">เลขที่</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.ivmrecvNumber }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">อ้างอิง</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.orderNumber }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">วันที่ย้าย</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.createdAt }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">ย้ายโดย</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.creator }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">วันที่รับ</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.updatedAt }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">รับโดย</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.receiver }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">จากคลัง</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.warehouseFrom }}</div>
                                            </div>
                                            <div style="display:flex;padding-top:5px;">
                                                <div style="display:inline-flex;flex-basis:25%"></div>
                                                <div style="display:inline-flex;flex-basis:25%">เข้าคลัง</div>
                                                <div style="display:inline-flex;flex-basis:50%">{{ order.warehouseTo }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr style="">
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:5%">#</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:10%">รูป</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:20%">ชื่อสินค้า</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:10%">จำนวนย้าย</th>
                            <th style="border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px;width:10%">จำนวนรับ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product,index) in order.toOrderItems" :key="'product_' + index">
                            <td style="text-align:center">{{ index + 1 }}</td>
                            <td style="text-align:center">
                                <img :src="product.imageURL" alt="" style="max-width:100px;max-height:100px">
                            </td>
                            <td>
                                {{ product.name }}
                                <div style="opacity:0.8;font-size:14px">{{ product.sku }}</div>
                                <div class="barcode" v-if="product.barCode">{{ '*' + product.barCode + '*' }}</div>
                                <div style="font-size:14px" v-if="product.note">
                                    หมายเหตุ : {{ product.note }}
                                </div>
                            </td>
                            <td style="text-align:right">{{ product.expectedQuantity }} {{ product.unit }}</td>
                            <td style="text-align:right">{{ product.quantity }} {{ product.unit }}</td>
                        </tr>
                        <template v-if="5 - order.toOrderItems.length > 0">
                            <tr v-for="pd in 5 - order.toOrderItems.length" :key="'temp_' + pd">
                                <td colspan="5" style="padding-top:50px;padding-bottom:50px"></td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="5">
                                <div style="display:flex;width:100%;page-break-inside:avoid;flex-direction:column;margin-top:auto">
                                <div style="display:flex;width:100%;border-top: 1px solid black;border-bottom: 1px solid black;padding-top:10px;padding-bottom:10px"></div>
                                <div style="display:flex;width:100%">
                                    หมายเหตุ : {{ order.note }}
                                </div>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="order.status == 'cancelled'" style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%) rotate(45deg);font-size:2.5em;border-style: solid;border-color:red;padding:10px;color:red">
                    ยกเลิก
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
}
</script>
